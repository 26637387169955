import { GetMeResponse } from 'api-schema'
import { UserContext } from './context'

export default function UserProvider({
  user,
  children,
}: {
  user: GetMeResponse['data']['user'] | null
  children: React.ReactNode
}) {
  return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
}
