const getShareImagePath = (locale?: string) => {
  switch (locale) {
    case 'es':
      return '/images/heja-share-es.png'
    case 'pt':
      return '/images/heja-share-pt.png'
    case 'de':
      return '/images/heja-share-de.png'
    default:
      return '/images/heja-share-en.png'
  }
}

export default getShareImagePath
