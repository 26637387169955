import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import {
  json,
  Links,
  Meta,
  MetaFunction,
  Outlet,
  redirect,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from '@remix-run/react'
import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node'

import './tailwind.css'
import { getLang } from './lib/getLang'
import SvgSprites from './components/SvgSprites'
import { appApi } from './lib/api/app-api'
import { GetMeResponse } from 'api-schema'
import UserProvider from './contexts/user/UserProvider'
import useLanguage from './contexts/language/useLanguage'
import { getSession } from './lib/auth/getSession'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import getShareImagePath from './lib/getShareImage'
import { IntercomProvider } from 'react-use-intercom'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
})

export const links: LinksFunction = () => [
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap',
  },
  { rel: 'icon', type: 'image/png', href: '/images/favicon-2.1-16x16.png', sizes: '16x16' },
  { rel: 'icon', type: 'image/png', href: '/images/favicon-2.1-32x32.png', sizes: '32x32' },
  { rel: 'icon', type: 'image/png', href: '/images/favicon-2.1-96x96.png', sizes: '96x96' },
]

export const meta: MetaFunction = ({ params }) => {
  return [
    { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' },
    { title: 'Heja | Sports team management and communication app' },
    {
      name: 'description',
      content:
        'Free app for your sports team. The only app you need to easily handle communication between coaches, managers, parents and players. Management, scheduling, availability, messaging, roster.',
    },
    { property: 'fb:app_id', content: '1830633657171618' },
    { property: 'og:type', content: 'website' },
    { property: 'og:locale', content: 'en_US' },
    { property: 'og:site_name', content: 'Heja' },
    { property: 'og:title', content: 'Heja | Sports team management and communication app' },
    {
      property: 'og:description',
      content:
        'Free app for your sports team. The only app you need to easily handle communication between coaches, managers, parents and players. Management, scheduling, availability, messaging, roster.',
    },
    { property: 'og:image', content: getShareImagePath(params.lang) },
    // { name: 'robots', content: process.env.NO_INDEX === 'true' ? 'noindex' : 'index' },
  ]
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url)

  if (url.origin === 'app.heja.io' && !url.pathname.startsWith('/deeplink/')) {
    return redirect('/deeplink/' + encodeURIComponent(url.pathname + url.search))
  }

  const lang = getLang({ path: url.pathname })
  const session = await getSession(request)

  let me: GetMeResponse | null = null
  if (session?.authToken) {
    try {
      const { data } = await appApi(session.authToken).get<GetMeResponse>('/me')
      me = data
    } catch (error) {
      console.log(error)
    }
  }

  return json({ lang, me })
}

export function Layout({ children }: { children: React.ReactNode }) {
  const ctx = useLanguage()
  return (
    <html lang={ctx}>
      <head>
        <meta charSet="utf-8" />
        <Meta />
        <Links />
        <script
          id="GoogleTagManager"
          dangerouslySetInnerHTML={{
            __html:
              "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-53R52C5');",
          }}
        />
      </head>
      <body>
        <SvgSprites />
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export function ErrorBoundary() {
  const error = useRouteError() as { status?: number }

  console.log(error)

  captureRemixErrorBoundaryError(error)

  return (
    <html lang="en">
      <body className="p-12">
        <h1>{error?.status ?? 500}</h1>
        Oh no, something went wrong
      </body>
    </html>
  )
}

function App() {
  const { me, lang } = useLoaderData<typeof loader>()
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider user={me?.data.user ?? null}>
        <IntercomProvider appId="ffiir0m4" autoBoot>
          <Outlet context={{ lang }} />
        </IntercomProvider>
      </UserProvider>
    </QueryClientProvider>
  )
}

export default withSentry(App)
